import React from 'react';
import PropTypes from 'prop-types';
import { Link as GatsbyLink } from 'gatsby';

import { themeShape } from 'styles/theme';

import {
  PresentationItemVisual,
  PresentationWrapper,
  StyledImage,
  StyledSection,
  StyledTitle,
} from './styled';
import Alphabet from './Alphabet';

const Kolumbia = ({ theme, link, images }) => (
  <StyledSection>
    <PresentationWrapper fontFamily={theme.fontFamily.Kolumbia}>
      <StyledTitle>Kolumbia</StyledTitle>
    </PresentationWrapper>
    <PresentationWrapper fontFamily={theme.fontFamily.Kolumbia}>
      <div>
        <p>
          Krój <strong>Kolumbia</strong> trafił do międzywojennej Polski aż z&nbsp;Ameryki. Na
          początku XX w., na podstawie ręcznego liternictwa z&nbsp;okładki „Saturday Evening Post”
          odlano tam krój w&nbsp;trzech odmianach – dwóch antykwach i&nbsp;kursywie. Następnie,
          z&nbsp; amerykańskiej odlewni ATF, Post Oldstyle Roman no. 2&nbsp;– bo tak brzmi pełna
          nazwa pierwowzoru – trafił za ocean, między innymi do niemieckiej odlewni Woellmera
          i&nbsp; holenderskiego Lettergieterij Amsterdam. Wreszcie dotarł do polskiej odlewni
          Jeżyńskiego, gdzie funkcjonował pod nazwą Kolonial oraz do odlewni Idźkowskiego, gdzie
          z&nbsp;kolei nazywano go Columbia (lub Kolumbia). Przy tworzeniu naszej Kolumbii
          posługiwaliśmy się wzornikami z&nbsp;obu warszawskich odlewni.
        </p>
        <p>
          Kolumbia to szeryfowy krój tytułowy. Dobrze sprawdzi się do składu krótkich treści oraz
          jako typograficzna para dla Renesansu. Autorami kroju są Ania Wieluńska i&nbsp;Borys
          Kosmynka.
        </p>
        <p>
          Więcej o&nbsp;typograficznej sytuacji dwudziestolecia międzywojennego oraz o&nbsp;kroju
          Kolumbia przeczytasz w&nbsp;
          <GatsbyLink to={link}>tekście Andrzeja Tomaszewskiego</GatsbyLink>.
        </p>
        <p>
          Opis kroju Kolumbia powstał na podstawie informacji z&nbsp;portalu{' '}
          <a
            target="_blank"
            rel="noreferrer"
            href="https://fontsinuse.com/typefaces/31913/post-oldstyle-roman"
          >
            fontsinuse.com
          </a>
        </p>

        <StyledImage fluid={images.Kolumbia} />
      </div>
      <PresentationItemVisual>
        <Alphabet />
      </PresentationItemVisual>
    </PresentationWrapper>
  </StyledSection>
);

Kolumbia.propTypes = { theme: themeShape, link: PropTypes.string, images: PropTypes.object };

export default Kolumbia;
