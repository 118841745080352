import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

import { StyledH1 } from 'components/Layout';
import { TextAndImageContainer } from 'components/Container';

import StubbedImage from './StubbedImage';

const StyledImageWrapper = styled.div`
  ${({ theme }) => css`
    ${theme.mediaQueries.smallDesktop.min} {
      order: 1;
      margin-top: 60px;
    }
  `}
`;

const StyledTextWrapper = styled.div`
  display: grid;
  grid-auto-flow: row;
`;

const StubbedContent = ({ children, title }) => (
  <TextAndImageContainer noPaddingRight>
    <StyledImageWrapper>
      <StubbedImage />
    </StyledImageWrapper>
    <StyledTextWrapper>
      <StyledH1>{title}</StyledH1>
      <p>
        Lorem ipsum dolor sit amet, consectetur adipisicing elit. Asperiores autem consequatur,
        dolorem dolores eius esse eum fugiat illo impedit iste itaque magni necessitatibus neque
        nisi, obcaecati officiis repudiandae, similique sit veniam voluptatibus. Assumenda ea
        expedita in iste magni nemo pariatur quisquam. A adipisci aliquam aliquid animi architecto
        atque corporis cum cumque debitis deleniti dicta dolores dolorum eaque et eum facere illo
        laborum magni molestiae nam nemo non numquam possimus quas quasi quis repellendus
        repudiandae saepe, sed similique sit tempore tenetur totam unde, veniam vero vitae? At
        consequuntur cumque distinctio dolor, enim esse est fuga fugit illum laboriosam laborum
        laudantium nesciunt nisi non quaerat quam quod ratione rem repellendus repudiandae, sequi
        sunt temporibus vel veniam voluptatibus! Aliquam, corporis ex illum laboriosam provident
        reprehenderit! Cupiditate dolores fugiat ipsa nihil praesentium sequi, unde? Aliquam dolore
        officiis quod suscipit vel? Ad aliquam cum cumque cupiditate deleniti deserunt dignissimos
        distinctio dolore ex excepturi exercitationem, explicabo fugit illo in laborum, magni maxime
        molestiae necessitatibus nemo nesciunt non numquam porro quae quasi quod recusandae
        repudiandae sint soluta temporibus totam? Amet doloribus modi molestias nisi nostrum numquam
        perferendis. Ad ea iure non obcaecati possimus quam reprehenderit sed unde voluptate
        voluptates. Aspernatur ex illum odit tempore voluptatibus! Dolorem, sunt!
      </p>

      {children}
    </StyledTextWrapper>
  </TextAndImageContainer>
);

StubbedContent.propTypes = {
  title: PropTypes.string,
  children: PropTypes.node,
};

export default StubbedContent;
