import React from 'react';
import PropTypes from 'prop-types';
import { graphql, useStaticQuery } from 'gatsby';
import styled, { css } from 'styled-components';
import GatsbyImage from 'gatsby-image';

import { StyledH1 } from 'components/Layout';
import { TextAndImageContainer } from 'components/Container';
import { StubbedImage } from 'components/StubbedContent';

import { StyledImageWrapper, StyledTextWrapper } from './styled';

export const PAGES = {
  HOME: 'home',
  OPRACOWANIA: 'opracowania',
  DO_POBRANIA: 'doPobrania',
};

export const IMAGE_COLORS = {
  LIGHT_GREEN: 'GREEN',
  LIGHT_BLUE: 'BLUE',
  LIGHT_ROSE: 'PINK',
};

const gronowskiMap = {
  1: {
    pageName: PAGES.HOME,
    color: IMAGE_COLORS.LIGHT_GREEN,
  },
  2: {
    pageName: PAGES.OPRACOWANIA,
    color: IMAGE_COLORS.LIGHT_ROSE,
  },
  3: {
    pageName: PAGES.DO_POBRANIA,
    color: IMAGE_COLORS.LIGHT_BLUE,
  },
};

export const StyledMobileImage = styled(GatsbyImage)`
  ${({ theme }) => css`
    ${theme.mediaQueries.smallDesktop.min} {
      display: none;
    }
  `}
`;

export const StyledDesktopImage = styled(GatsbyImage)`
  display: none;

  ${({ theme }) => css`
    ${theme.mediaQueries.smallDesktop.min} {
      display: block;
    }
  `}
`;

const HeroSection = ({ children, title, withImage, page }) => {
  const { allImageSharp } = useStaticQuery(graphql`
    query HeroImages {
      allImageSharp(filter: { fixed: { originalName: { regex: "/GRONOWSKI.+/" } } }) {
        edges {
          node {
            fluid(sizes: "(max-width: 991px) 991px, (max-width: 1199px) 390px, 440px") {
              ...GatsbyImageSharpFluid
              originalName
            }
          }
        }
      }
    }
  `);

  const images = allImageSharp.edges.reduce(
    (
      acc,
      {
        node: {
          fluid: { originalName, ...fluid },
        },
      },
    ) => {
      const currentColor = originalName.substring(0, originalName.indexOf('.')).split('_')[2];

      const gronowskiInfo = gronowskiMap[originalName.match(/\d/)[0]];

      if (currentColor !== gronowskiInfo.color) {
        return acc;
      }

      const { pageName } = gronowskiInfo;
      const platform = originalName.split('_')[1].toLowerCase();

      if (!acc[pageName]) {
        acc[pageName] = {};
      }

      acc[pageName][platform] = fluid;

      return acc;
    },
    {},
  );

  return (
    <TextAndImageContainer noPaddingRight>
      {withImage && (
        <StyledImageWrapper>
          <StyledMobileImage fluid={images[page].mobile} />
          <StyledDesktopImage fluid={images[page].desktop} />
        </StyledImageWrapper>
      )}

      <StyledTextWrapper>
        <StyledH1>{title}</StyledH1>

        {children}
      </StyledTextWrapper>
    </TextAndImageContainer>
  );
};

HeroSection.propTypes = {
  title: PropTypes.string,
  children: PropTypes.node,
  withImage: PropTypes.bool,
  page: PropTypes.oneOf(Object.values(PAGES)),
};

HeroSection.defaultProps = { withImage: true };

export default HeroSection;
