import React from 'react';
import PropTypes from 'prop-types';

import { PresentationWrapper, StyledImage, StyledSection, StyledTitle } from './styled';

const Instrukcja = ({ images }) => (
  <React.Fragment>
    <StyledSection>
      <PresentationWrapper>
        <StyledTitle>Instrukcja tworzenia plakatu reklamowego</StyledTitle>
      </PresentationWrapper>
      <PresentationWrapper>
        <div>
          <p>
            Dzięki zawartej w&nbsp;paczce instrukcji dowiesz się, jakie cechy stylu największych
            grafików międzywojnia powinieneś naśladować, by uzyskać w&nbsp;rezultacie plakat
            czytelny, estetyczny, popularny i&nbsp;skuteczny.
          </p>
          <p>
            Pamiętaj! Plakat łączy ze sobą warstwę tekstową i&nbsp;wizualną. Przygotowując hasło na
            plakat wiedz, że powinno być ono zwięzłe, zrozumiałe, pełne entuzjazmu
            i&nbsp;wystosowane w&nbsp;tonie apelu. Ważne, by wskazywało na znajomość sprzedawanego
            towaru. Unikać należy przesady, nieścisłości i&nbsp;braku logiki.
          </p>
          <p>
            Typografię trzeba dostosować do treści plakatu, ilustracji oraz całościowego układu
            graficznego. Wielu autorów decydowało się na tworzenie typografii autorskiej. Większa
            część plakatu przeznaczona jest na ilustrację. Tekst w&nbsp;tradycyjnym plakacie
            znajduje się u&nbsp; dołu strony i&nbsp;zajmuje, w&nbsp;zależności od wybranej
            proporcji, 1/4 lub 1/3 jej wysokości. Ilustracja, jak i&nbsp;cały plakat, są
            wielobarwne.
          </p>
        </div>
      </PresentationWrapper>
    </StyledSection>

    <StyledSection>
      <PresentationWrapper>
        <div>
          <StyledTitle>Plakat w&nbsp;stylu Edmunda Bartłomiejczyka</StyledTitle>

          <p>Charakterystyczne cechy:</p>
          <ul>
            <li>inspiracja drzeworytem</li>
            <li>swojskie motywy</li>
            <li>prostota formy</li>
            <li>dekoracyjna geometryzacja</li>
            <li>obecność ornamentu</li>
            <li>ozdobne elementy w&nbsp;układzie symetrycznym</li>
            <li>tradycjonalizm</li>
            <li>ludowość w&nbsp;tzw. „stylu narodowym”</li>
            <li>stosowanie przecinających się linii</li>
            <li>kolumna tekstu na szerokość ilustracji</li>
            <li>płaskie plamy barwne</li>
            <li>cieniowanie za pomocą równoległych linii, takich jak w&nbsp;drzeworycie</li>
          </ul>
        </div>

        <div>
          <StyledImage $noMargin fluid={images.plakatEdmunda} />
        </div>
      </PresentationWrapper>
    </StyledSection>

    <StyledSection>
      <PresentationWrapper>
        <div>
          <StyledTitle>Plakat w&nbsp;stylu Tadeusza Gronowskiego</StyledTitle>

          <p>Charakterystyczne cechy:</p>
          <ul>
            <li>
              technika prószu z&nbsp;użyciem aerografu, nadaje wrażenie rozświetlenia
              i&nbsp;przestrzenności
            </li>
            <li>użycie gradientów</li>
            <li>geometryczny, syntetyczny rysunek, zajmujący większą część plakatu</li>
            <li>dekoracyjna typografia ułożona po skosie u&nbsp;dołu plakatu</li>
            <li>wykorzystanie autorskiej typografii</li>
            <li>
              jasne, wibrujące kolory (różowy, seledynowy, niebieski), skontrastowane z&nbsp;czernią
            </li>
            <li>biała linia zaznaczająca kontur</li>
            <li>hasło reklamowe podkreślające elegancję i&nbsp;wytworność potencjalnego klienta</li>
          </ul>
        </div>

        <div>
          <StyledImage $noMargin fluid={images.plakatTadeusza} />
        </div>
      </PresentationWrapper>
    </StyledSection>

    <StyledSection>
      <PresentationWrapper>
        <div>
          <StyledTitle>Plakat w&nbsp;stylu Henryka Berlewiego</StyledTitle>

          <p>Charakterystyczne cechy:</p>
          <ul>
            <li>dwa kolory – czerwony i&nbsp;czarny na białym tle</li>
            <li>uniwersalność formy, prostota elementów</li>
            <li>reklama konstruktywistyczna</li>
            <li>wykorzystanie kontrastu</li>
            <li>
              teoria mechanofaktury: plakat ma być wykonany mechanicznie, jakby zrobiła go maszyna!
            </li>
            <li>odrzucenie głębi na rzecz dwuwymiarowości – jestem płaski i&nbsp;awangardowy!</li>
            <li>proste, geometryczne kształty (koła i&nbsp;kwadraty)</li>
            <li>rytmizacja elementów</li>
            <li>dynamiczne układy typograficzne</li>
            <li>różne kroje i&nbsp;stopnie pisma, autorska typografia</li>
            <li>podkreślenie istotnych informacji za pomocą czerwonego koloru</li>
            <li>
              hasła krótkie, bezpośrednie i&nbsp;dynamiczne, sformułowane jak odezwa, zakończone
              wykrzyknikami!
            </li>
          </ul>
        </div>

        <div>
          <StyledImage $noMargin fluid={images.plakatHenryka} />
        </div>
      </PresentationWrapper>
    </StyledSection>
  </React.Fragment>
);

Instrukcja.propTypes = { images: PropTypes.object };

export default Instrukcja;
