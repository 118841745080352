import styled, { css } from 'styled-components';

export const StyledH1 = styled.h1`
  font-weight: ${({ theme }) => theme.fontWeight.medium};
  margin-top: 32px;
  margin-bottom: 16px;

  ${({ theme }) => css`
    ${theme.mediaQueries.smallDesktop.min} {
      margin-top: 28px;
      margin-bottom: 50px;
    }
  `}
`;
