import React from 'react';
import styled, { withTheme } from 'styled-components';
import { graphql, useStaticQuery } from 'gatsby';

import { themeShape } from 'styles/theme';
import { texts } from 'components/PagesSpecific/Opracowania/constants';

import * as urls from '../../../../urls';

import Renesans from './Renesans';
import Kolumbia from './Kolumbia';
import Makkabi from './Makkabi';
import Ilustracje from './Ilustracje';
import Instrukcja from './Instrukcja';

export const StyledWrapper = styled.div`
  a {
    @media (any-hover: hover) {
      &:hover {
        color: ${({ theme }) => theme.colors.dark};
      }
    }
  }
`;

const link = `${urls.opracowania}#${texts.tomaszewski.id}`;

const FontsPresentation = ({ theme }) => {
  const { allImageSharp } = useStaticQuery(graphql`
    query FontPresentationImages {
      allImageSharp(filter: { fixed: { originalName: { regex: "/fontPresentation-.+/" } } }) {
        edges {
          node {
            fluid(
              sizes: "(max-width: 991px) 991px, (max-width: 1199px) 764px, (max-width: 1919px) 600px, 1320px"
            ) {
              ...GatsbyImageSharpFluid
              originalName
            }
          }
        }
      }
    }
  `);

  const images = allImageSharp.edges.reduce(
    (
      acc,
      {
        node: {
          fluid: { originalName, ...fluid },
        },
      },
    ) => {
      acc[
        originalName
          .substring(0, originalName.indexOf('Image.jpg'))
          .replace('fontPresentation-', '')
      ] = fluid;

      return acc;
    },
    {},
  );

  return (
    <StyledWrapper>
      <Kolumbia theme={theme} images={images} link={link} />

      <Renesans theme={theme} images={images} link={link} />

      <Makkabi theme={theme} images={images} />

      <Ilustracje />

      <Instrukcja images={images} />
    </StyledWrapper>
  );
};

FontsPresentation.propTypes = {
  theme: themeShape,
};

export default withTheme(FontsPresentation);
