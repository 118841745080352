import styled, { css } from 'styled-components';
import GatsbyImage from 'gatsby-image';

import { TextAndImageContainer } from 'components/Container';
import Icon from 'components/Icon';

export const StyledSection = styled.section`
  margin-top: 50px;

  ul {
    list-style: initial;
    padding-left: 40px;
  }
`;

export const PresentationWrapper = styled(TextAndImageContainer)`
  ${({ fontFamily }) =>
    fontFamily &&
    css`
      * {
        font-family: ${fontFamily};
      }

      p,
      a {
        font-family: ${({ theme }) => theme.fontFamily.Kapra};
      }
    `}
`;

export const StyledTitle = styled.h2`
  margin-bottom: 20px;
`;

export const StyledAlphabet = styled.p``;

export const StyledImage = styled(GatsbyImage)`
  margin-top: 20px;

  ${({ theme, $noMargin }) =>
    $noMargin &&
    css`
      ${theme.mediaQueries.smallDesktop.min} {
        margin-top: 0;
      }
    `}
`;

export const StyledIcon = styled(Icon)`
  width: 100%;
  height: auto;
`;

export const PresentationItemVisual = styled.div`
  order: -1;
  margin-bottom: 20px;

  ${({ theme }) => css`
    ${theme.mediaQueries.smallDesktop.min} {
      order: 0;
      margin-bottom: 0;
    }
  `}
`;
