import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { graphql, useStaticQuery } from 'gatsby';
import { OutboundLink } from 'gatsby-plugin-google-analytics';

export const BigButtonWrapper = styled(OutboundLink)`
  padding-top: 16px;
  padding-bottom: 16px;
  width: 100%;
  max-width: 370px;
  border-radius: 16px;
  margin-left: auto;
  margin-right: auto;
  background-color: ${({ theme }) => theme.colors.black};
  color: ${({ theme }) => theme.colors.white};
  font-size: ${({ theme }) => theme.font.size24};
  font-family: ${({ theme }) => theme.fontFamily.Kolumbia};
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  text-transform: uppercase;
  text-decoration: none;
  line-height: normal;
  border: none;

  ${({ theme }) => css`
    ${theme.mediaQueries.tablet.min} {
      width: auto;
      display: inline-block;
      max-width: none;
      padding: 20px 60px;
      border-radius: 20px;
      margin-left: 0;
      font-size: ${({
        theme: {
          font: { size50 },
        },
      }) => size50};
    }

    ${theme.mediaQueries.smallDesktop.min} {
      font-size: ${({
        theme: {
          font: { size36 },
        },
      }) => size36};
    }

    ${theme.mediaQueries.largeDesktop.min} {
      font-size: ${({
        theme: {
          font: { size45 },
        },
      }) => size45};
    }

    ${theme.mediaQueries.extraLargeDesktop.min} {
      font-size: ${({
        theme: {
          font: { size50 },
        },
      }) => size50};
    }
  `}
`;

const BigButton = ({ children, className }) => {
  const {
    file: { publicURL },
  } = useStaticQuery(graphql`
    {
      file(name: { eq: "AFISZUJ_SIE" }) {
        publicURL
      }
    }
  `);

  return (
    <BigButtonWrapper target="_blank" rel="noreferrer" href={publicURL} className={className}>
      {children}
    </BigButtonWrapper>
  );
};

BigButton.propTypes = { children: PropTypes.node, className: PropTypes.string };

export default BigButton;
