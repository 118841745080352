import styled from 'styled-components';

const HeroLink = styled.a`
  display: block;

  @media (any-hover: hover) {
    &:hover {
      color: ${({ theme }) => theme.colors.dark};
    }
  }
`;

HeroLink.propTypes = {};

export default HeroLink;
