export const ids = {
  aleksandra: 'aleksandra',
  tomaszewski: 'tomaszewski',
  warda: 'warda',
};

export const texts = {
  aleksandra: {
    id: ids.aleksandra,
    author: 'Aleksandra Maria Lewandowska-Ferenc',
    title: 'Sztuka zupełnie poważna',
  },
  tomaszewski: {
    id: ids.tomaszewski,
    author: 'Andrzej Tomaszewski',
    title: 'Typografia międzywojennej reklamy',
  },
  warda: {
    id: ids.warda,
    author: 'Michał Warda',
    title: 'Plakat i reklama. Dwudziestolecie międzywojenne',
  },
};
