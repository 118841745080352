import React from 'react';
import styled from 'styled-components';

export const StyledWrapper = styled.div`
  font-size: ${({ theme }) => theme.font.size36};
`;

const Alphabet = () => (
  <StyledWrapper>
    <div>A B C D E F G H I J K L M N O P Q R S T U V W X Y Z</div>
    <div>a b c d e f g h i j k l m n o p q r s t u v w x y z</div>
    <div>1 2 3 4 5 6 7 8 9 0</div>
  </StyledWrapper>
);

Alphabet.propTypes = {};

export default Alphabet;
