import React from 'react';

const AfiszujsieHashtag = () => (
  <a href="https://www.instagram.com/explore/tags/afiszujsie/" target="_blank" rel="noreferrer">
    #afiszujsie
  </a>
);

AfiszujsieHashtag.propTypes = {};

export default AfiszujsieHashtag;
