import React from 'react';
import styled from 'styled-components';

import AfiszujsieHashtag from 'components/AfiszujsieHashtag';
import ilustracjeSvg from 'assets/icons/fontPresentation-ilustracje.svg';

import {
  PresentationItemVisual,
  PresentationWrapper,
  StyledIcon,
  StyledSection,
  StyledTitle,
} from './styled';

export const StyledWrapper = styled.div``;

const Ilustracje = () => (
  <StyledSection>
    <PresentationWrapper>
      <StyledTitle>Ilustracje</StyledTitle>
    </PresentationWrapper>
    <PresentationWrapper>
      <div>
        <p>
          W&nbsp;paczce znajdziesz dekoracyjne elementy graficzne wybrane z&nbsp;reklam
          i&nbsp;ogłoszeń drobnych, pochodzące z&nbsp;czasopism „Teatr i&nbsp;Życie Wytworne” oraz
          „Światowid”. Oprócz tego przygotowaliśmy instrukcję tworzenia plakatu reklamowego wraz
          z&nbsp;analizą stylistyki najsłynniejszych grafików międzywojnia – Tadeusza Gronowskiego,
          Henryka Berlewiego i&nbsp;Edmunda Bartłomiejczyka. Przykładowe plakaty przygotował Igor
          Kubik. Paczka zawiera również elementy graficzne, stworzone przez Igora Kubika
          i&nbsp;wykorzystane przez niego w&nbsp;kompozycji plakatów.
        </p>
        <p>
          Wszystkie elementy graficzne są udostępniane za darmo i&nbsp;przeznaczone do dalszego
          wykorzystania. Zachęcamy do tworzenia własnych grafik i&nbsp;wrzucania ich na Instagrama
          z&nbsp; hasztagiem <AfiszujsieHashtag />.
        </p>
      </div>
      <PresentationItemVisual>
        <StyledIcon svg={ilustracjeSvg} />
      </PresentationItemVisual>
    </PresentationWrapper>
  </StyledSection>
);

Ilustracje.propTypes = {};

export default Ilustracje;
