import React from 'react';
import PropTypes from 'prop-types';

import { themeShape } from 'styles/theme';

import {
  PresentationItemVisual,
  PresentationWrapper,
  StyledImage,
  StyledSection,
  StyledTitle,
} from './styled';
import Alphabet from './Alphabet';

const Makkabi = ({ images, theme }) => (
  <StyledSection>
    <PresentationWrapper fontFamily={theme.fontFamily.Makkabi}>
      <StyledTitle>Makkabi</StyledTitle>
    </PresentationWrapper>
    <PresentationWrapper fontFamily={theme.fontFamily.Makkabi}>
      <div>
        <p>
          <strong>Makkabi</strong> to międzywojenny krój, który wykorzystywany był w&nbsp;dwóch
          warszawskich drukarniach (W. Spiegelsteina i&nbsp;drukarni Rekord). Zestaw znaków
          oryginału obejmował jedynie wersaliki (wielkie litery) w&nbsp;dużej punktacji i&nbsp;był
          na tyle ograniczony, że niektóre litery występujące w&nbsp;składzie musiały być
          zastępowane literami z&nbsp;innych krojów. Pierwotnie krój występował w&nbsp;formie
          drewnianej czcionki. Stosowano go przede wszystkim w&nbsp;afiszach sportowych, które
          zachęcały do udziału w&nbsp; meczach piłkarskich klubu Makabi – Żydowskiego Towarzystwa
          Gimnastyczno-Sportowego. Krój ten znany jest z&nbsp;okładek książek Szczepana Twardocha
          („Król”, „Królestwo”), zaprojektowanych przez Rafała Kucharczuka. Równolegle do okładek
          powstawała też pierwsza wersja cyfrowego kroju Makkabi, autorstwa Filipa Tofila. Do
          publikacji na naszej stronie krój Filipa przygotowała Ania Wieluńska.
        </p>
        <p>
          W&nbsp;stosunku do oryginalnych liter z&nbsp;afiszy udostępniane przez nas Makkabi
          uzupełnione zostało o&nbsp;zestaw minuskuły (małych liter), cyfr i&nbsp;znaków
          interpunkcyjnych. Makkabi to krój displayowy – sprawdzi się przy składzie dużych, krótkich
          napisów. Najlepiej zestawiać go z&nbsp;oszczędnymi, bezszeryfowymi krojami.
        </p>

        <StyledImage fluid={images.Makkabi} />
      </div>
      <PresentationItemVisual>
        <Alphabet />
      </PresentationItemVisual>
    </PresentationWrapper>
  </StyledSection>
);

Makkabi.propTypes = { theme: themeShape, images: PropTypes.object };

export default Makkabi;
