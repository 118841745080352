import React from 'react';
import PropTypes from 'prop-types';
import { Link as GatsbyLink } from 'gatsby';

import { themeShape } from 'styles/theme';

import {
  PresentationItemVisual,
  PresentationWrapper,
  StyledImage,
  StyledSection,
  StyledTitle,
} from './styled';
import Alphabet from './Alphabet';

const Renesans = ({ theme, images, link }) => (
  <StyledSection>
    <PresentationWrapper fontFamily={theme.fontFamily.Renesans}>
      <StyledTitle>Renesans</StyledTitle>
    </PresentationWrapper>
    <PresentationWrapper fontFamily={theme.fontFamily.Renesans}>
      <div>
        <p>
          Pierwowzór kroju <strong>Renesans</strong> powstał w&nbsp;1901 r. w&nbsp;berlińskiej
          odlewni Hermanna Bertholda i&nbsp;otrzymał wówczas nazwę Secession. Występował
          w&nbsp;kilku odmianach grubościowych i&nbsp;ozdobnych. Jego kopię odlano później
          w&nbsp;warszawskiej odlewni Idźkowskiego. Nadano jej też nową nazwę – Renesans, która nie
          miała wiele wspólnego z&nbsp; rysunkiem liter. Nasza wersja Renesansu bazuje na
          najgrubszej odmianie z&nbsp;wzorników Idźkowskiego. W&nbsp;stosunku do oryginału jego
          proporcje zostały nieco zmienione, tak, by lepiej odpowiadały proporcjom Kolumbii.
        </p>
        <p>
          Renesans to ozdobny krój bezszeryfowy. Ma charakter displayowy – nadaje się do nagłówków,
          tytułów, na okładkę i&nbsp;plakat. Przeznaczony jest do używania w&nbsp;zestawie z&nbsp;
          naszym drugim krojem – Kolumbią. Jego autorami są Ania Wieluńska i&nbsp;Borys Kosmynka.
        </p>
        <p>
          Więcej o&nbsp;typograficznej sytuacji dwudziestolecia międzywojennego oraz o&nbsp;kroju
          Renesans przeczytasz w&nbsp;
          <GatsbyLink to={link}>tekście Andrzeja Tomaszewskiego</GatsbyLink>.
        </p>
        <p>
          Opis kroju Renesans powstał na podstawie informacji z&nbsp;portalu{' '}
          <a
            target="_blank"
            rel="noreferrer"
            href="https://fontsinuse.com/typefaces/27633/secession"
          >
            fontsinuse.com
          </a>
        </p>

        <StyledImage fluid={images.Renesans} />
      </div>
      <PresentationItemVisual>
        <Alphabet />
      </PresentationItemVisual>
    </PresentationWrapper>
  </StyledSection>
);

Renesans.propTypes = { theme: themeShape, link: PropTypes.string, images: PropTypes.object };

export default Renesans;
