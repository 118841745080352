import React from 'react';
import { Helmet } from 'react-helmet';

import SEO from 'components/Seo';
import {
  DoPobraniaHeroSectionContent,
  FontsPresentation,
} from 'components/PagesSpecific/DoPobrania';
import HeroSection, { PAGES } from 'components/HeroSection';

const DoPobrania = () => (
  <React.Fragment>
    <Helmet>
      <body className="lightBlue" />
    </Helmet>
    <SEO title="Do pobrania" />

    <HeroSection page={PAGES.DO_POBRANIA} title="Do pobrania">
      <DoPobraniaHeroSectionContent />
    </HeroSection>

    <FontsPresentation />
  </React.Fragment>
);

export default DoPobrania;
