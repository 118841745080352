import React from 'react';
import styled from 'styled-components';

import AfiszujsieHashtag from 'components/AfiszujsieHashtag';

import BigButton from './BigButton';

export const StyledWrapper = styled.div`
  a {
    @media (any-hover: hover) {
      &:hover {
        color: ${({ theme }) => theme.colors.dark};
      }
    }
  }
`;

export const StyledBigButton = styled(BigButton)`
  margin-right: auto;
  margin-top: 60px;
`;

const DoPobraniaHeroSectionContent = () => (
  <StyledWrapper>
    <p>
      Poniżej znajdziesz paczkę z&nbsp;trzema krojami pisma, zdigitalizowanymi na podstawie
      międzywojennych afiszy i&nbsp;druków ulotnych. Są to: szeryfowa Kolumbia, bezszeryfowy
      Renesans i&nbsp; ozdobne Makkabi. Możesz pobrać je i&nbsp;korzystać z&nbsp;nich zupełnie za
      darmo. Fonty zostały udostępnione na licencji{' '}
      <a
        target="_blank"
        rel="noreferrer"
        href="https://scripts.sil.org/cms/scripts/page.php?site_id=nrsi&id=OFL"
      >
        SIL Open Font License
      </a>
      . Przeznaczone są do użytku prywatnego i&nbsp;komercyjnego.
    </p>

    <p>
      Oprócz fontów w&nbsp;paczce znajdziesz także ilustracje pochodzące z&nbsp;ogłoszeń drobnych
      publikowanych w&nbsp;międzywojennej prasie, instrukcję tworzenia plakatu reklamowego
      w&nbsp;stylu Tadeusza Gronowskiego, Henryka Berlewiego i&nbsp;Edmunda Bartłomiejczyka, wraz
      z&nbsp;przykładowymi reklamami wykonanymi przez Igora Kubika, elementy graficzne z&nbsp;tych
      plakatów, które możesz wykorzystać do złożenia swojej własnej grafiki. Całość udostępniona
      jest za darmo i&nbsp; przeznaczona do dalszego wykorzystania.
    </p>

    <p>
      Pobierz paczkę, przygotuj swój własny plakat reklamowy i&nbsp;wrzuć go na Instagrama
      z&nbsp;hasztagiem <AfiszujsieHashtag />.
    </p>

    <StyledBigButton>Pobierz (210 MB)</StyledBigButton>
  </StyledWrapper>
);

DoPobraniaHeroSectionContent.propTypes = {};

export default DoPobraniaHeroSectionContent;
