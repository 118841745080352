import styled, { css } from 'styled-components';

import { paddings } from 'components/Container';

export const StyledImageWrapper = styled.div`
  margin: 0 -${paddings.padding20};
  width: auto;

  ${({ theme }) => css`
    ${theme.mediaQueries.smallDesktop.min} {
      order: 1;
      margin-top: 60px;
    }
  `}
`;

export const StyledTextWrapper = styled.div``;
